import Vue from 'vue'
import Vuex from 'vuex'

import image from "@/assets/logos/logo_segurifyNew.png"
import banner from "@/assets/banner/bannerComparador.gif"

import LogoAna from "@/assets/logos/logo_anaSeguros.png"
import bannerAna from "@/assets/banner/bannerComparadorAna.gif"

import LogoCoru from "@/assets/logos/Trans_BK_Pina.png"

// axa
import LogoAxa from "@/assets/logos/AXA_png.png";
import BannerAxa from "@/assets/banner/SEGURIFY_GIF_AXA_200x644_270622.gif";

// hdi
import logoHdi from "@/assets/logos/hdi_new.svg";
import BannerHdi from "@/assets/banner/segurify_HDI_1.gif";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aseguradoraMeta: {
      name:'comparador',
      color: "#152189",
      img:image,
      banner: banner,
      has_menu: true,
      has_cotizacion: true,
      has_telefono: true,
      has_banner: true,
      portal: 'segurify',
      meta: {
        descripcion: 'Segurify tu Comparador de Seguros de Auto. Comparar y Contratar el Seguro de Autoen México, al mejor precio, es fácil y rápido con Segurify.'
      }
    },
    planes: ['amplia', 'amplia-plus', 'rc', 'limitada'],
    cotizaciones: [],
    Nocotizado: [],
    ready: false,
    rules: {
      required: v => (!!v || v===0) || 'El campo es requerido',
      requiredArray: v => (v.length > 0) || 'El campo es requerido',
      email: v => {
          if((v || '').length==0){
              return true;
          }else{
              return /[a-z|A-Z]{3}@.+\..+/.test(v) || 'Correo no válido'
          }
      },
      numeric: v => { return /^[0-9]+$/.test(v) || !v  || 'El campo solo acepta números' },
      decimal: v => { return /^[0-9]+(.[0-9]+)?$/.test(v) || !v || 'El campo solo acepta números con punto decimal' },
      celular: (value)=>{
        var compare = [
          '(11) 1111 1111',
          '(22) 2222 2222',
          '(33) 3333 3333',
          '(44) 4444 4444',
          '(55) 5555 5555',
          '(66) 6666 6666',
          '(77) 7777 7777',
          '(88) 8888 8888',
          '(12) 3456 7890',
          '(09) 8765 4321',
          '(55) 9999 9999',
          '(55) 1234 5678',
          '(55) 5658 1111',
          '(55) 5555 5551',
          '(55) 5555 5552',
          '(55) 5555 5553',
          '(55) 5555 5554',
          '(55) 5555 5556',
          '(55) 5555 5557',
          '(55) 5555 5558',
          '(55) 5555 5559',
          '(55) 5555 5550',
          '(51) 1111 1111',
          '(12) 3456 7890',
          '(00) 0000 0000',
          '(00) 0000 0001',
          '(10) 0000 0000',
          '(20) 0000 0000',
          '(30) 0000 0000',
          '(40) 0000 0000',
          '(60) 0000 0000',
          '(70) 0000 0000',
          '(80) 0000 0000',
          '(90) 0000 0000',
          '(55) 5555 5551',
          '(55) 5555 5552',
          '(55) 5555 5553',
          '(55) 5555 5554',
          '(55) 5555 5556',
          '(55) 5555 5557',
          '(55) 5555 5558',
          '(55) 5555 5559',
          '(12) 1212 3123',
        ]
        // var length = value.length;
        var mycomparison = compare.indexOf(value);
        if(mycomparison != -1){
          return 'Telefono no valido'
        }
        return mycomparison == -1;
      },
      curp: (value) => {
          var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          validado = value.match(re);
          if (!validado)  //Coincide con el formato general?
              return false;
  
          //Validar que coincida el dígito verificador
          function digitoVerificador(curp17) {
              //Fuente https://consultas.curp.gob.mx/CurpSP/
              var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
              lngSuma      = 0.0,
              lngDigito    = 0.0;
              for(var i=0; i<17; i++)
                  lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
                  lngDigito = 10 - lngSuma % 10;
                  if (lngDigito == 10) return 0;
                  return lngDigito;
          }
          return ((validado[2] != digitoVerificador(validado[1]))) ? 'La curp no es válida' : true;
      },
      rfc: (value) => {
          if(value == null){
              return false;
          }
          // hay un error en la validacion de a rfc
          const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
          let correcto = value.match(regex);//SE CREA UN OBJETO CON LA EXPRESIÓN REGULAR
  
          if(!correcto){ 
              // si no es correcto, se termina el proceso
              return 'La RFC no es valida';
          }
          const digitoVerificador = correcto.pop(), //se obtendra el ultimo digito del rfc
              rfcSinDigito      = correcto.slice(1).join(''), //se guarda el rfc sin el digito
              len               = rfcSinDigito.length,//Se obtiene la longitud del rfc sin el digito verificador, 11 o 12 
      
          //Obtener el digito esperado
              diccionario       = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
              indice            = len + 1; //Se obtendra la suma de la longitud del rfc sin el digito verificador mas 1
  
          var   suma, digitoEsperado; //Se declaran las variables
          suma = (len == 12) ? 0 : 481;//Ajuste para persona moral o fisica 0 es para una persona fisica, 481 es para una persona moral
          for(var i=0; i<len; i++){
              suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i); //Se hacer una operacion, donde se obtiene la posición del caracter del rfc 
          }

          digitoEsperado = 11 - suma % 11;
          if (digitoEsperado == 11) digitoEsperado = 0;
          else if (digitoEsperado == 10) digitoEsperado = "A";
  
          //El dígito verificador coincide con el esperado
          return ( (digitoVerificador != digitoEsperado) ) ? 'La RFC no es valida' : true;
      },
      longDigit(number){
        return v => (v || '').length == number || 'Debe proporcionar '+number+' digitos';
      }, 
      long(number){
        return v => (v || '').length <= number || `Solo se permite ${number} caracteres como máximo`;
      },
      validDate: v => {
        return /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(v) || !v  || 'Fecha no valida'
      }
    },
    Nocotiza: false,
  },
  mutations: {
    setAseguradora(state, payload){
      state.aseguradoraMeta = payload;
    },
    setCotizaciones(state, payload){
      try{
        if(payload != null){
          // let aux = payload;
          // aux.vehiculoJson = JSON.parse(payload.vehiculoJson);
      
          // let cotizacion = payload;
          // cotizacion.vehiculoJson = aux.vehiculoJson
          // cotizacion.vehiculoJson.modelo = {id: aux.vehiculoJson.modelo.id.id};
          // cotizacion.vehiculoJson = JSON.stringify(cotizacion.vehiculoJson);
          state.cotizaciones.push(payload);
        }else{
          state.cotizaciones.push(payload);
        }

        if(state.cotizaciones.length == state.planes.length){
          state.ready = true;
          for(let i = 0; i < state.cotizaciones.length; i++){
            if(state.cotizaciones[i] != null){
              state.ready = true;
              break
            }
          }
        }
      }catch(exception){
        console.error(exception)
      }
    },
    setNoCotiza(state, payload){
      state.Nocotizado.push(payload);
      if(state.cotizaciones.length == state.planes.length){
        state.Nocotiza = true;
      }else{
        state.Nocotiza = false;
      }
    }
  },
  actions: {
    getAseguradora(context, payload){
      switch (payload) {
        case 'qualitas':
          context.commit('setAseguradora',{
            name:'qualitas',
            aseguradora:'qualitas',
            color: "#872982",
            img:image,
            banner: banner,
            notFound: false,
            has_menu: true,
            has_cotizacion: true,
            has_telefono: true,
            has_banner: true,
            has_send: true,
            has_botones: true,
            portal: 'qualitas',
            titulo: 'Qualitas',
            tag:false,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto con Qualitas. Comparar y Contratar el Seguro de Auto en México, al mejor precio, es fácil y rápido con Segurify.',
              text_1: "Ponemos a tu disposición la oferta de las mejores aseguradoras del país con excelentes coberturas, que te darán la tranquilidad de estar protegido.<br><br>Sabemos lo difícil que es elegir la protección para tu auto, por lo que te llevaremos de la mano para contratar el mejor y más completo seguro para tu auto de una forma rápida, confiable y personalizada, en tan solo un par de clics.",
              text_2: "Segurify te permite comparar entre las ofertas y beneficios que te ofrecen las mejores aseguradoras del país, todo en menos de 20 segundos. <br><br>En solo un par de clics descubrirás el precio más bajo así como la mejor protección para tu auto.",
              text_3: "Somos una empresa mexicana, especializada en seguros para automóviles con más de 24 años de experiencia. <br>Nuestro principal objetivo es estar a la vanguardia para cumplir con los estándares de calidad que el mercado asegurador requiere.",
              text_4: "Esto nos permitirá mostrarte el seguro que más te conviene y lo mejor, ¡el más económico!",
              text_5: "Queremos asegurar tu auto sea lo más sencillo para ti, por lo que uno de nuestros ejecutivos te contactará para ayudarte a encontrar la mejor protección para tu auto.",
              text_6: "Una vez que hayas decidido qué Aseguradora es la que más te conviene, te enviaremos la póliza digital de tu seguro de auto y podrás disfrutar de la tranquilidad de estar protegido con el mejor seguro y al mejor precio."
            }
          })
          break;
        case 'hdi':
          context.commit('setAseguradora',{
            name:'hdi',
            aseguradora:'hdi',
            color: "#006731",
            img:logoHdi,
            banner: BannerHdi,
            notFound: false,
            has_menu: true,
            has_cotizacion: true,
            has_telefono: true,
            has_banner: true,
            has_send: true,
            has_botones: true,
            portal: 'hdi',
            titulo: 'HDI',
            tag:true,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto con HDI. Comparar y Contratar el Seguro de Auto en México, al mejor precio, es fácil y rápido con Segurify.',
              text_1: "Somos especialistas en el ramo de Auto, buscamos brindar la mejor protección con productos innovadores y enfocados en las necesidades de nuestros clientes siempre cuidando su economía, esto nos ha permitido ser una de las compañías aseguradoras más importantes en México con más de 600,000 asegurados, además, contamos con presencia en más de 150 países a nivel mundial.",
              text_2: "Al contratar tu Seguro de Auto con HDI contarás con una protección innovadora y personalizada que se adaptará a tus necesidades, siempre de la mano con una atención rápida, de calidad y, lo más importante disponible en cualquier momento que lo requieras.",
              text_3: "Esto nos permitirá mostrarte el seguro que más te conviene y lo mejor, ¡el más económico!.",
              text_4: "Queremos asegurar tu auto sea lo más sencillo para ti, por lo que uno de nuestros ejecutivos te contactará para ayudarte a encontrar la mejor protección para tu auto.",
              text_5: "Una vez que hayas contratado con HDI, te enviaremos la póliza digital de tu seguro de auto y podrás disfrutar de la tranquilidad de tener una protección confiable e innovadora."
            }
          })
          break;
        case 'ana':
          context.commit('setAseguradora',{
            name:'ana',
            aseguradora:'ana',
            color: "#c2002f",
            img:LogoAna,
            banner: bannerAna,
            notFound: false,
            has_menu: true,
            has_cotizacion: true,
            has_telefono: true,
            has_banner: true,
            has_send: true,
            has_botones: true,
            portal: 'ana',
            titulo: 'Ana Seguros',
            tag:false,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto con ANA. Comparar y Contratar el Seguro de Auto en México, al mejor precio, es fácil y rápido con Segurify.',

              text_1: "Somos una empresa mexicana, especializada en seguros para automóviles con más de 24 años de experiencia. <br><br>Nuestro principal objetivo es estar a la vanguardia para cumplir con los estándares de calidad que el mercado asegurador requiere.",
              text_2: "Al contratar tu Seguro de Auto con Ana Seguros contarás con una póliza innovadora de la mano con personal especialista y altamente capacitado que estará disponible para ti las 24 hrs. los 365 días, con más de 105 oficinas a Nivel Nacional. <br><br>Nuestros productos están pensados en ofrecerte una protección total ante las amenazas más comunes del camino, no importa a donde vayas.",
              text_3: "Esto nos permitirá mostrarte el seguro que más te conviene y lo mejor, ¡el más económico!",
              text_4: "Queremos asegurar tu auto sea lo más sencillo para ti, por lo que uno de nuestros ejecutivos te contactará para ayudarte a encontrar la mejor protección.",
              text_5: "Una vez que hayas contratado con Ana Seguros, te enviaremos la póliza digital de tu seguro de auto y podrás disfrutar de la tranquilidad de tener una protección confiable e innovadora a donde vayas."
            }
          })
          break;
        case 'axa':
          context.commit('setAseguradora',{
            name:'axa',
            aseguradora:'axa',
            color: "#00008F",
            // color: "#027180",
            img:LogoAxa,
            banner: BannerAxa,
            notFound: false,
            has_menu: true,
            has_cotizacion: true,
            has_telefono: true,
            has_banner: true,
            has_send: true,
            has_botones: true,
            portal: 'axa',
            titulo: 'AXA',
            tag:true,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto. Comparar y Contratar el Seguro de Auto en México, al mejor precio, es fácil y rápido con Segurify.',
              text_1: "En el país, desde 2008 ofrecemos protección a 7.3 millones de asegurados, garantizando un servicio innovador y de excelencia para nuestros clientes.",
              text_2: "Al proteger tu auto con AXA Seguros contarás con una póliza pensada en brindarte la mejor protección para ti y tu vehículo, garantizando un excelente servicio al cliente y lo mejor, con beneficios exclusivos.",
              text_3: "Esto nos permitirá mostrarte el seguro que más te conviene y lo mejor, ¡el más económico!",
              text_4: "Queremos asegurar tu auto sea lo más sencillo para ti, por lo que uno de nuestros ejecutivos te contactará para ayudarte a encontrar la mejor protección para tu vehículo.",
              text_5: "Una vez que hayas contratado con AXA Seguros, te enviaremos la póliza digital de tu seguro de auto y podrás disfrutar de la tranquilidad de tener una protección confiable e innovadora."
            }
          })
          break;
        case 'coru':
          context.commit('setAseguradora',{
            name:'coru-aig',
            aseguradora:'aig',
            color: "#0B0146",
            img:LogoCoru,
            banner: null,
            notFound: false,
            has_menu: false,
            has_cotizacion: false,
            has_telefono: false,
            has_banner: false,
            has_send: false,
            has_botones: false,
            portal: "Coru-aig",
            titulo: 'AIG',
            tag:true,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto con AIG. Comparar y Contratar el Seguro de Auto en México, al mejor precio, es fácil y rápido con Segurify.',

              text_1: "Somos el resultado de décadas de trabajo, esfuerzo e innovación, nos caracterizamos por siempre dar la mejor experiencia de servicio a nuestros clientes en los más de 130 países en los que tenemos asegurados.",
              text_2: "Al proteger tu auto con AIG contarás con una póliza completa, pensada en brindarte la mejor protección, de la mano con un servicio al cliente excepcional. Además, contarás con beneficios adicionales que te darán la tranquilidad que necesitas al momento de estar en tu vehículo.",
              text_3: "Esto nos permitirá mostrarte el seguro que más te conviene y lo mejor, ¡el más económico!",
              text_4: "Queremos asegurar tu auto sea lo más sencillo para ti, por lo que uno de nuestros ejecutivos te contactará para ayudarte a encontrar la mejor protección.",
              text_5: "Una vez que hayas contratado con AIG, te enviaremos la póliza digital de tu seguro de auto y podrás disfrutar de la tranquilidad de tener una protección confiable e innovadora a donde vayas."
            }
          })
          break;
        case 'comparador':
          context.commit('setAseguradora',{
            name:null,
            aseguradora:'comparador',
            color: "#152189",
            img:image,
            banner: banner,
            notFound: false,
            has_menu: true,
            has_cotizacion: true,
            has_telefono: true,
            has_banner: true,
            has_send: true,
            has_botones: true,
            portal: 'segurify',
            titulo: 'Segurify',
            tag:false,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto. Comparar y Contratar el Seguro de Autoen México, al mejor precio, es fácil y rápido con Segurify.',

              text_1: "Ponemos a tu disposición la oferta de las mejores aseguradoras del país con excelentes coberturas, que te darán la tranquilidad de estar protegido. <br><br>Sabemos lo difícil que es elegir la protección para tu auto, por lo que te llevaremos de la mano para contratar el mejor y más completo seguro para tu auto de una forma rápida, confiable y personalizada, en tan solo un par de clics.",
              text_2: "Segurify te permite comparar entre las ofertas y beneficios que te ofrecen las mejores aseguradoras del país, todo en menos de 20 segundos. <br><br>En solo un par de clics descubrirás el precio más bajo así como la mejor protección para tu auto.",
              text_3: "Esto nos permitirá mostrarte el seguro que más te conviene y lo mejor, ¡el más económico!",
              text_4: "Queremos asegurar tu auto sea lo más sencillo para ti, por lo que uno de nuestros ejecutivos te contactará para ayudarte a encontrar la mejor protección para tu auto.",
              text_5: "Una vez que hayas decidido qué Aseguradora es la que más te conviene, te enviaremos la póliza digital de tu seguro de auto y podrás disfrutar de la tranquilidad de estar protegido con el mejor seguro y al mejor precio."
            }
          })
          break;
        default:
          context.commit('setAseguradora',{
            name: null,
            aseguradora: null,
            color: "#152189",
            img:image,
            banner: banner,
            notFound: true,
            has_menu: true,
            has_cotizacion: true,
            has_telefono: true,
            has_banner: true,
            has_send: true,
            has_botones: true,
            portal: 'segurify',
            tag:false,
            meta: {
              descripcion: 'Segurify tu Comparador de Seguros de Auto. Comparar y Contratar el Seguro de Autoen México, al mejor precio, es fácil y rápido con Segurify.'
            }
          });
          break;
      }
    }
  },
  modules: {
  }
})