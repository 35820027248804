<template>
  <v-app-bar app color="primary" height="83" dark dense elevation="6">
    <div class="d-flex align-center" style="background-color:white;">
      <v-img :src="aseguradoraMeta.img" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="110" height="75"
        contain></v-img>
    </div>

    <v-spacer></v-spacer>

    <v-btn text @click="getRoute('Home')" v-text="'¡Cotiza!'"></v-btn>

    <template v-if="aseguradoraMeta.has_menu">
      <v-menu rounded offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text v-bind="attrs" v-on="on"> Sobre Nosotros</v-btn>
        </template>

        <v-list>
          <v-list-item @click="getRoute('About')">
            <v-list-item-title>Acerca de Segurify</v-list-item-title>
          </v-list-item>

          <v-list-item @click="getRoute('Aviso')">
            <v-list-item-title>Aviso de privacidad</v-list-item-title>
          </v-list-item>

          <v-list-item @click="getRoute('Terminos')">
            <v-list-item-title>Términos y condiciones</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu rounded offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text v-bind="attrs" v-on="on">Ayuda</v-btn>
        </template>

        <v-list>
          <v-list-item @click="getRoute('Ayuda')">
            <v-list-item-title>Preguntas Frecuentes</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text @click="getRoute('Siniestro')" v-text="'Siniestro'"></v-btn>
      <v-btn text @click="getRoute('Contacto')" v-text="'Contacto'"></v-btn>
    </template>

    <v-btn v-show="aseguradoraMeta.has_telefono" text href="tel:+525588730900">
      <span>TELÉFONO: 5588730900</span>
    </v-btn>

  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Base',
  data: () => ({
    image: null,
  }),
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  beforeMount() { },
  methods: {
    refresh() {
      location.reload();
    },
    getRoute(name = 'Home') {
      if (name == 'Home') {
        if (this.$route.name == 'Home') {
          location.reload();
        } else {
          this.ValidRoute(name);
        }
      }
      this.ValidRoute(name);
    },
    ValidRoute(name) {
      if (this.aseguradoraMeta.name != null) {
        this.$router.push({ name: name, params: { aseguradora: this.aseguradoraMeta.aseguradora }, replace: true });
      } else {
        if (this.aseguradoraMeta.notFound) {
          this.$router.push({ name: '404', params: { aseguradora: null }, replace: true })
        } else {
          this.$router.push({ name: name, params: { aseguradora: null }, replace: true })
        }
      }
    }
  },
};
</script>


<style>
.v-toolbar__content {
  border-bottom: 8px solid #555559cc !important;
}
</style>