<template>
  <v-card class="mb-12" flat>
    <v-row>
      <v-col cols="12">
        <v-row style="text-align: center">
          <v-col cols="12">
            <h2 style="text-align: center">
              Selecciona el modelo de tu vehículo
            </h2>
          </v-col>
        </v-row>

        <v-row v-if="loading" justify="center" class="text-center">
          <v-col cols="12">
            <v-progress-circular
              :size="100"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row dense v-else>
          <v-col cols="6" xs="12" sm="6" md="4" lg="2" xl="2" v-for="(modelo, i) in modelosFiltrados" v-bind:key="i">
            <v-btn :depressed="true" :dark="modelo.id == modeloSeleccionado" @click="seleccionarModelo(modelo.id)"
              style="width: 100%; padding: 10px; font-size: 18px" small>
              {{ modelo.id }}
            </v-btn>
            <!-- <v-card flat outlined class="text-center" color="#f5f5f5" style="width: 100%; padding: 3px; font-size: 18px" :dark="modelo.id == modeloSeleccionado" @click="seleccionarModelo(modelo.id)">
              {{ modelo.id }}
            </v-card> -->
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="6" lg="4" lx="2" v-if="!verTodos">
            <v-btn color="primary" :depressed="true" @click="verTodos = true"
              style="width: 100%; padding: 10px; font-size: 18px" small>Ver Más</v-btn>
          </v-col>

          <v-col v-if="verTodos" cols="6" sm="6" md="4" lg="2" xl="2">
            <v-btn :depressed="true" @click="verTodos = false" style="width: 100%; padding: 10px; font-size: 18px">Ver
              Menos</v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    verTodos: false,
    modelos: [],
    modeloSeleccionado: null,
    loading: false,
  }),
  computed: {
    ...mapState(['aseguradoraMeta']),
    modelosFiltrados() {
      if (this.verTodos == false) {
        return this.modelos.slice(0, 20);
      } else {
        return this.modelos;
      }
    }
  },
  methods: {
    seleccionarModelo(modelo) {
      if(this.aseguradoraMeta.tag){
        this.$gtag.event("login", { method: "Google" });
      }
      this.modeloSeleccionado = modelo.id;
      this.$emit("seleccionarModelo", modelo);
    },
  },
  mounted() {
    this.loading = true;
    let aseguradora = "qualitas"
    if (this.aseguradoraMeta.name != null) {
      aseguradora = this.aseguradoraMeta.aseguradora;
    }
    this.axios.get(`v1/vehiculo/${aseguradora}/modelos`).then((response) => {
      this.loading = false;
      this.modelos = response.data;
    }).catch((exception)=>{
      console.error(exception);
      this.loading = false;
    });
  },
};
</script>
