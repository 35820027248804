<template>
  <v-card class="mb-12" flat>
    <v-row>
      <v-col cols="12">
        <v-row style="text-align: center">
          <v-col cols="12">
            <h2 style="text-align: center">
              Selecciona la marca de tu vehículo
            </h2>
          </v-col>
        </v-row>

        <template  v-if="loading" >
          <v-row  justify="center" class="text-center">
            <v-col cols="12">
              <v-progress-circular
                :size="100"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row v-if="verTodas">
            <v-col cols="12">
              <v-select @change="seleccionarMarca" :items="marcas" item-text="marca" dense label="Selecciona la marca"
                return-object></v-select>
            </v-col>
          </v-row>

          <v-row v-if="!verTodas" dense>
            <v-col cols="12" sm="12" md="6" lg="3" xl="3" v-for="(marca, i) in marcas" v-bind:key="i">
              <v-btn :depressed="true" @click="seleccionarMarca(marca)"
                style="width: 100%; padding: 10px; font-size: 18px" small>{{ marca.marca }}</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="!verTodas">
            <v-col cols="12" sm="12" md="6" lg="2" lx="2">
              <v-btn color="primary" @click="verTodas = !verTodas" :depressed="true" small block>Ver Todas</v-btn>
            </v-col>
          </v-row>
        </template>

        <v-row justify="center" class="text-center">
          <v-col cols="12">
            <v-btn text outlined elevation="2" class="mr-2" @click="$emit('back', 'modelo')">Regresar</v-btn>
            <v-btn color="primary" v-if="verTodas" @click="verTodas = !verTodas">Ver Principales</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ["modelo"],
  data: () => ({
    marcas: [],
    verTodas: false,
    marcaSeleccionada: null,
    loading: false,
  }),
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  mounted() {
    this.loading = true;
    let aseguradora = "qualitas"
    if (this.aseguradoraMeta.name != null) {
      aseguradora = this.aseguradoraMeta.aseguradora;
    }
    this.axios.get(`v1/vehiculo/${aseguradora}/marca/${this.modelo}`).then((response) => {
      this.loading = false;
      this.marcas = response.data;
    }).catch((exception)=>{
      console.error(exception)
      this.loading = false;
    });
  },
  methods: {
    seleccionarMarca(marca) {
      this.$emit('seleccionarMarca', marca);
    }
  }
};
</script>
