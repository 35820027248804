import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta';
import VueMask from 'v-mask';
// import LogoAna from "./assets/logos/logo_anaSeguros.png"
// import bannerAna from "./assets/banner/bannerComparadorAna.gif"

Vue.use(VueMask);
Vue.use(VueMeta)
Vue.config.productionTip = false


import "@/assets/main.css"
Vue.prototype.$getAge = (year)=>{
  let age = 0;
  let today = new Date();
  age = today.getFullYear() - year;
  return age;
}
Vue.prototype.$generateCotizacion = (max = 6)=>{
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';  
  // const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result1= '';
  const charactersLength = characters.length;
  for ( let i = 0; i < max; i++ ) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result1;
}
Vue.prototype.$formatMoney = (money = 0)=>{
  let divisa = new Intl.NumberFormat('es-MX',{
    style:'currency',
    currency: 'MXN'
  });
  return divisa.format(money)
}

const host = window.location.host;
const parts = host.split('.');
const domainLength = 3; // route1.example.com => domain length = 3

const aseguradora = () => {
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    return "segurify";
  } else if (parts[0] === 'ana') {
    return "ana";
  } else if (parts[0] === 'coru') {
    return "coru";
  }else if (parts[0] === 'axa') {
    return "axa";
  }else if (parts[0] === 'hdi') {
  return "hdi";
}
  return "segurify";
};

if(aseguradora()=='coru'){
  Vue.use(VueGtag, {
    config: { id: "GTM-P5ZFJFJ" }
  });
}
if(aseguradora()=='axa'){
  Vue.use(VueGtag, {
    config: { id: "GTM-W3CWXMJ" }
  });
}
if(aseguradora()=='hdi'){
  Vue.use(VueGtag, {
    config: { id: "GTM-MNM9MP7" }
  });
}

store.dispatch('getAseguradora', aseguradora());

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
