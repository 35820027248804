<template>
    <v-img
        :src="aseguradoraMeta.banner"
        alt="Seguros Segurify - Cotizador de Seguros de Auto"
        height="550"
    ></v-img>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
      ...mapState(['aseguradoraMeta'])
    }, 
}
</script>
