let moment = require('moment-timezone');

async function cotiza(modelo, marca, submarca, version, plan, aseguradora, identificador, forma_pago, usuarioJson, portal, campania, cotizacion_general, age) {
  var cotizacionReq = {
    "vehiculo": aseguradora == 'aig' ? `${identificador}-${modelo}` : identificador,
    "forma-pago": forma_pago,
    "plan": plan,
    "identificador": aseguradora == 'aig' ? `${identificador}-${modelo}` : identificador,
    "edad": age,
    "sexo": usuarioJson.sexo,
    "codigoPostal": usuarioJson.cp,
    "modelo": modelo,
    "cotizacion_general": cotizacion_general,
    "usuarioJson": usuarioJson,
    "vehiculoJson": {
      "generoConductor": usuarioJson.sexo,
      "modelo": {
        "id": modelo
      },
      "marca": marca,
      "submarca": submarca,
      "vehiculo": version
    },
    "portal": portal,
    "campania": campania
  };
  
  let {data} = await window.axios.post(`v1/${aseguradora}/cotiza/landing`, cotizacionReq);
  return data;
}
async function enviaLead(usuarioJson, modelo, marca, submarca, version, medio) {
  let request = {
    nombre: usuarioJson.name,
    apellidoPaterno: usuarioJson.lastname,
    apellidoMaterno: usuarioJson.lastname2,
    telefono: usuarioJson.phone,
    fechaContratacion: "Inmediato",
    correo: usuarioJson.email,
    sexo: usuarioJson.sexo,
    fechaNacimiento: usuarioJson.birthYear + '-' + usuarioJson.birthMonth + '-' + usuarioJson.birthDay,
    cp: usuarioJson.cp,
    marca: marca.id,
    submarca: submarca.id,
    version: version.nombre,
    modelo: modelo.id,
    medio: medio
  };
  let { data } = await window.axios.post(`/v1/lead`, request);
  return data;
}
async function envioSinCotizar(usuarioJson, aseguradora, modelo, marca, submarca, version, plan) {
  let format2 = moment(new Date());
  let local = moment(format2).tz("America/Mexico_City");
  let request = {
    nombre: usuarioJson.name,
    apellido: usuarioJson.lastname,
    telefono: usuarioJson.phone,
    aseguradora: aseguradora,
    fechaEstimadaContratacion: "Inmediato",
    correo: usuarioJson.email,
    genero: usuarioJson.sexo,
    fechaNacimiento: usuarioJson.birthYear + '-' + usuarioJson.birthMonth + '-' + usuarioJson.birthDay,
    codigoPostal: usuarioJson.cp,
    marca: marca.id,
    submarca: submarca.id,
    version: version.nombre,
    modelo: modelo.id,
    plan: plan,
    fecha_lead: local.format('YYYY-MM-DD'),
    hora_lead: local.format('HH:mm'),
  };
  let { data } = await window.axios.post(`/v1/lead/sin-cotizacion`, request);
  return data;
}
export { cotiza, envioSinCotizar,enviaLead }
