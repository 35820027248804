<template>
  <v-app>
    <template v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <NavMobile />
    </template>

    <template v-else>
      <Nav></Nav>
    </template>

    <v-main>
      <router-view />
    </v-main>

    <v-footer color="primary" padless>
      <v-row justify="center" no-gutters>
        <v-col class="primary py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }}. <strong>Agente autorizado. Consulta nuestros <span
              style="cursor: pointer; text-decoration: underline;" @click="getRoute('Terminos')">Términos y
              condiciones</span> y nuestro <span style="cursor: pointer; text-decoration: underline;"
              @click="getRoute('Aviso')">Aviso de privacidad</span></strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import Nav from "@/components/Nav.vue";
import NavMobile from "@/components/NavMobile.vue";

export default {
  name: 'Base',
  components: {
    Nav,
    NavMobile
  }, data: () => ({
    image: null,
  }), computed: {
    ...mapState(['aseguradoraMeta'])
  },beforeMount() { 

  }, methods: {
    refresh() {
      location.reload();
    },
    getRoute(name = 'Home') {
      if (name == 'Home') {
        if (this.$route.name == 'Home') {
          this.refresh();
        } else {
          this.ValidRoute(name);
        }
      }
      this.ValidRoute(name);
    },
    ValidRoute(name) {
      if (this.aseguradoraMeta.name != null) {
        this.$router.push({ name: name, params: { aseguradora: this.aseguradoraMeta.aseguradora }, replace: true });
      } else {
        if (this.aseguradoraMeta.notFound) {
          this.$router.push({ name: '404', params: { aseguradora: null }, replace: true })
        } else {
          this.$router.push({ name: name, params: { aseguradora: null }, replace: true })
        }
      }
    }
  },
};
</script>


<style>
.v-toolbar__content {
  border-bottom: 8px solid #555559cc !important;
}
</style>