export default async function sendCotizacion(cotizaciones, aseguradora, email) {
    try {
        console.log('Entre a la funcion de send de correo cotiza');
        let form = {
            correo: email,
            cotizaciones: cotizaciones
        };
        let { data } = await window.axios.post(`/v1/cotizacion/correo/aseguradora/${aseguradora}`, form);
        console.log(data);
        return true;
    } catch (exception) {
        return false
    }
}