<template>
    <v-row>
        <v-col cols="12">
            <v-card flat dense>
                <!-- <v-card-title class="text-h5 lighten-2">¡Contáctanos!</v-card-title> -->
                <v-card-text>
                    <v-row dense>
                        <v-col cols="6">
                            <p class="text-h4">
                                Déjanos tus datos y en breve recibirás una llamada de nosotros. O si lo prefieres comunícate al telefono 5588730900 y con gusto te atenderemos.
                            </p>
                        </v-col>

                        <v-col cols="6" class="text-h4">
                            <v-form ref="contact">
                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="contact.nombre"
                                            label="Nombre"
                                            dense
                                            outlined
                                            :rules="[rules.required]"
                                            color="success"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="contact.correo"
                                            label="Correo"
                                            dense
                                            outlined
                                            :rules="[rules.required, rules.email]"
                                            color="success"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="contact.telefono"
                                            label="Telefono"
                                            v-mask="'(##) #### ####'"
                                            dense
                                            outlined
                                            :rules="[rules.required, rules.celular, rules.longDigit(14)]"
                                            color="success"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="contact.mensaje"
                                            label="Mensaje"
                                            hint="Minimo 30 caracteres"
                                            outlined
                                            dense
                                            :counter="30"
                                            color="success"
                                            :rules="[rules.required, rules.celular, rules.long(30)]"

                                        ></v-textarea>
                                    </v-col>
                                </v-row>

                                <v-row dense>
                                    <v-col cols="12">
                                        <v-btn color="" @click="enviar" :disabled="loading">{{loading ? 'Enviando...': 'Enviar'}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-dialog v-model="dialog" width="500" hide-overlay transition="dialog-top-transition">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                Mensaje enviado
                </v-card-title>

                <v-card-text>
                    <p style="margin-top:20px;">Se ha enviado tu solicitud de contacto, en breve uno de nuestros ejecutivos te contactará.</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:"ContactoView",
    data() {
        return {
            loading: false,
            dialog:false,
            contact: {
                nombre: null,
                correo: null,
                telefono: null,
                mensaje: null
            }
            
        }
    }, computed: {
      ...mapState(['rules', 'aseguradoraMeta'])
    }, mounted(){

    },methods: {
        async enviar(){
            if(!this.$refs.contact.validate()){
                return false
            }
            this.loading = true;
            this.contact.landing = this.aseguradoraMeta.aseguradora;
            await window.axios.post('/v1/lead/contacto', this.contact);
            this.loading = false;
            this.dialog = true;
            setTimeout(()=>{
                this.dialog = false;
            }, 3000);
        }
    }
}
</script>

<style>

</style>