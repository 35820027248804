<template>
  <v-card class="mb-12" flat>
    <v-row>
      <v-col cols="12">
        <v-row style="text-align: center">
          <v-col cols="12">
            <h2 style="text-align: center">
              Selecciona la submarca de tu vehículo
            </h2>
          </v-col>
        </v-row>

        <v-row v-if="loading" justify="center" class="text-center">
          <v-col cols="12">
            <v-progress-circular
              :size="100"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>

        <v-row dense v-else>
          <v-col cols="12" sm="12" md="6" lg="6" v-for="(submarca, i) in submarcas" v-bind:key="i">
            <!-- <v-btn :depressed="true" @click="seleccionarSubmarca(submarca)"
              style="width: 100%; padding: 10px; font-size: 18px" small>
              {{ submarca.nombre }}
            </v-btn> -->
            <v-card flat outlined class="text-center" color="#f5f5f5" style="width: 100%; padding: 3px; font-size: 18px" @click="seleccionarSubmarca(submarca)">
              {{ submarca.nombre }}
            </v-card>
          </v-col>
        </v-row>

        <v-row justify="center" class="text-center">
          <v-col cols="12">
            <v-btn text outlined elevation="2" @click="$emit('back', 'marca')">Regresar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ["marca", "modelo"],
  data: () => ({
    submarcas: [],
    submarcaSeleccionada: null,
    loading: false,
  }),
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  mounted() {
    this.loading = true;
    let aseguradora = "qualitas"
    if (this.aseguradoraMeta.name != null) {
      aseguradora = this.aseguradoraMeta.aseguradora;
    }

    this.axios.get(`v1/vehiculo/${aseguradora}/submarca/${this.marca}/${this.modelo}`).then((response) => {
      this.loading = false;
      this.submarcas = response.data;
    }).catch((exception)=>{
      console.error(exception);
      this.loading = false;
    });
  },
  methods: {
    seleccionarSubmarca(submarca) {
      this.$emit('seleccionarSubmarca', submarca);
    }
  }
};
</script>
