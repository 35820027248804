<template>
  <div>
    <v-row>
      <v-col cols="4" md="4" lg="4" xl="4">
        <div style="text-align: center; margin-bottom: 40px">
          <!-- <v-avatar color="primary" :size=" $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80': '100'" @click="boton1()">
            <img :src="icon_1" alt="Seguros Segurify - Cotizador de Seguros de Auto">
          </v-avatar> -->

          <v-btn fab :color="vista_1 ? 'primary' : '#263238'" x-large @click="boton1()">
            <img :src="icon_1" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="70">
          </v-btn>
        </div>
      </v-col>

      <v-col cols="4" md="4" lg="4" xl="4">
        <div style="text-align: center; margin-bottom: 40px">
          <!-- <v-avatar color="primary" :size=" $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80': '100'" @click="boton2()">
            <img :src="icon_2" alt="Seguros Segurify - Cotizador de Seguros de Auto">
          </v-avatar> -->

          <v-btn fab :color="vista_2 ? 'primary' : '#263238'" x-large @click="boton2()">
            <img :src="icon_2" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="70">
          </v-btn>
        </div>
      </v-col>
      
      <v-col cols="4" md="4" lg="4" xl="4">
        <div style="text-align: center; margin-bottom: 40px">
          <!-- <v-avatar color="primary" :size=" $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80': '100'" @click="boton3()">
            <img :src="icon_3" alt="Seguros Segurify - Cotizador de Seguros de Auto">
          </v-avatar> -->

          <v-btn fab :color="vista_3 ? 'primary' : '#263238'" x-large @click="boton3()">
            <img :src="icon_3" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="70">
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="vista_1">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                <h1 class="text-center text-h4 mb-4">¿Por qué {{aseguradoraMeta.titulo}}?</h1>
                <p class="text-justify" v-html="aseguradoraMeta.meta.text_1"></p>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-img
                  :height=" $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300': '400'"
                  src="@/assets/img/SEGURIFY_comparador_1.png"
                  alt="Seguros Segurify - Cotizador de Seguros de Auto"
                  lazy
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="vista_2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                <h1 class="text-center text-h4 mb-4">¿Qué te ofrecemos? </h1>
                <p class="text-justify" v-html="aseguradoraMeta.meta.text_2"></p>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-img
                  :height=" $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300': '400'"
                  src="@/assets/img/SEGURIFY_comparador_2.png"
                  alt="Seguros Segurify - Cotizador de Seguros de Auto"
                  lazy
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="vista_3">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="8" lg="8" xl="8">
                <h1 class="text-center text-h4 mb-4">¡Rápido y seguro!</h1>

                <h2 class="text-h6">1. Ingresa los datos de tu auto, así como los del conductor habitual.</h2>
                <p class="text-justify" v-html="aseguradoraMeta.meta.text_3"></p>

                <h2 class="text-h6">2. Registra tus datos de contacto.</h2>
                <p class="text-justify" v-html="aseguradoraMeta.meta.text_4"></p>

                <h2 class="text-h6">3. ¡Elegiste seguro!</h2>
                <p class="text-justify" v-html="aseguradoraMeta.meta.text_5"></p>
              </v-col>

              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-img
                  :height=" $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '300': '400'"
                  src="@/assets/img/SEGURIFY_comparador_3.png"
                  alt="Seguros Segurify - Cotizador de Seguros de Auto"
                  lazy
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import search from "@/assets/icons/SEGURIFY_landing_icono_001_porque.png"
import choice from "@/assets/icons/SEGURIFY_landing_icono_002_compara.png"
import segure from "@/assets/icons/SEGURIFY_landing_icono_003_rapido.png"
import { mapState } from 'vuex'
export default {
  data: function(){
    return{
      icon_1:search,
      icon_2:choice,
      icon_3:segure,

      vista_1: false,
      vista_2: false,
      vista_3: false,
    }
  },
  computed: {
      ...mapState(['aseguradoraMeta'])
  }, mounted(){
    this.vista_1 = true;
  }, methods:{
    boton1(){
      this.vista_1 = true;
      this.vista_2 = false;
      this.vista_3 = false;
    }, boton2(){
      this.vista_1 = false;
      this.vista_2 = true;
      this.vista_3 = false;
    }, boton3(){
      this.vista_1 = false;
      this.vista_2 = false;
      this.vista_3 = true;
    },

  }
};
</script>
