<template>
  <div>
    <v-card dense flat>
      <v-card-text>
        <v-row>
          <template v-if="aseguradoraMeta.has_banner">
            <v-col cols="2" class="d-sm-none d-md-flex" v-show="!$vuetify.breakpoint.xs">
              <Banner />
            </v-col>
          </template>

          <v-col cols="12" sm="12" :md="aseguradoraMeta.has_banner ? '10' : '12'"
            :lg="aseguradoraMeta.has_banner ? '10' : '12'">
            <v-row class="mt-2">
              <v-col cols="12" style="text-align: center">
                <h1 class="text-sm-h3 text-md-h3 text-lg-h3 text-xl-h3">¡Cotiza tu seguro de auto en segundos!</h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <Stepper />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card dense class="mt-4" flat>
      <v-card-text>
        <ComparadorContent />
      </v-card-text>
    </v-card>

    <!-- <v-fab-transition>
      <v-btn v-show="aseguradoraMeta.has_telefono" color="primary" dark fixed  right fab href="tel:+525588730900" :x-large="$vuetify.breakpoint.xl ? true: false" :bottom="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" :top="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
        <v-icon lg>mdi-phone</v-icon>
      </v-btn>
    </v-fab-transition> -->
    
    <v-speed-dial v-if="aseguradoraMeta.has_telefono" :bottom="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" :top="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" right direction="right" app transition="slide-x-reverse-transition" fixed style="margin-top: 80px !important; right: 8px;">
      <template v-slot:activator class="dd-flex justify-center text-center">
          <v-btn :color="color" dark fab href="tel:+525588730900" :x-large="$vuetify.breakpoint.xl  ? true: false" large style="left:17px;">
            <v-icon>mdi-phone</v-icon>
          </v-btn><br>
          <h1 class="text-button white--text rounded" :style="`background-color:${aseguradoraMeta.color}b8`">5588730900</h1>
      </template>
    </v-speed-dial>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from "./components/PromoBanner.vue";
import Stepper from "./components/Stepper.vue";
import ComparadorContent from './home/static/ComparadorHome.vue'

export default {
  name: "Home",
  data: () => ({
    color: "primary"
  }),
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  mounted() {
    this.$meta().refresh();
    // this.changeColor()
  }, methods: {
    changeColor(){
      setInterval(()=>{
        if(this.color == "primary"){
          this.color = "#546E7A"
        }else if(this.color == "#546E7A"){
          this.color = "primary"
        }
      },1000);
    }
  }, components: {
    Banner,
    Stepper,
    ComparadorContent,
  },
};
</script>

<style scoped>
  .v-btn--absolute.v-btn--top, .v-btn--fixed.v-btn--top {
      top: 90px;
  }

</style>