<template>
  <div>
    <v-row justify="space-between" dense>
      <v-col cols="12" sm="12" md="6" lg="6">
        <p style="margin-top: 20px">
          Hola, {{ usuarioJson.name }} Estás cotizando un:
        </p>
        <p style="margin-top: 10px">
          {{ modelo }} {{ marca.marca }}, {{ vehiculo.nombre }}
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6" v-if="aseguradoraMeta.has_botones">
        <v-row dense>
          <v-col cols="12">
            <v-btn color="primary" @click="downloadPdf" :disabled="!ready">Descargar Cotización</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn color="primary" @click="enviarCotizacion" :disabled="!ready" :loading="loading">
              Eviar Cotización por correo electrónico
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row class="mt-3" dense>
      <v-col cols="12" md="3" v-for="plan in planes" v-bind:key="plan">
        <CotizacionIndividualAxa :usuarioJson="usuarioJson" :vehiculo="vehiculo" :modelo="modelo" :marca="marca"
          :submarca="submarca" :plan="plan" v-if="aseguradoraMeta.aseguradora == 'axa'"/>

        <CotizacionIndividual :usuarioJson="usuarioJson" :vehiculo="vehiculo" :modelo="modelo" :marca="marca"
          :submarca="submarca" :plan="plan" v-else/>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500" hide-overlay transition="dialog-top-transition">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Correo enviado
        </v-card-title>

        <v-card-text class="mt-4">
          <p style="margin-top: 20px">
            Se ha enviado la cotización a {{ usuarioJson.email }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ModalNoCotiza" width="500" hide-overlay transition="dialog-top-transition">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Error al cotizar
        </v-card-title>

        <v-card-text class="mt-4">
          <p style="margin-top: 20px">
            Upss... creo que algo salió mal, te buscaremos para ayudarte a encontrar el mejor precio para tu seguro de
            automóvil.
          </p>
          <p class="text-center">¡Elige seguro, elige Segurify!</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CotizacionIndividual from "./CotizacionIndividualComparador.vue";
import CotizacionIndividualAxa from "./CotizacionIndividualComparadorAxa.vue";
import downloadCotizacion from "./../../../util/Download.js";
import sendCotizacion from "./../../../util/sendCotizacion.js";
import { enviaLead, envioSinCotizar } from "../../../util/CotizaHelper.js";

export default {
  props: ["usuarioJson", "vehiculo", "marca", "submarca", "modelo"],
  data: () => ({
    dialog: false,
    loading: false,
    ModalNoCotiza: false
  }),
  computed: {
    ...mapState(["planes", "cotizaciones", "ready", "Nocotiza", "aseguradoraMeta"]),
  },
  mounted() {
    enviaLead(
      this.usuarioJson,
      this.modelo,
      this.marca,
      this.submarca,
      this.vehiculo,
      this.aseguradoraMeta.portal
    );
    if(this.aseguradoraMeta.tag){
      this.$gtag.event("lead_success", {
        marca: this.marca,
        modelo: this.modelo,
        cp: this.usuarioJson.cp,
      });
    }
  },
  watch: {
    ready: async function (val) {

      if (this.aseguradoraMeta.has_send) {
        if (val) {
          this.loading = true;
          let send = await sendCotizacion(this.cotizaciones, this.aseguradoraMeta.aseguradora, this.usuarioJson.email)
          this.loading = false;
          this.dialog = send;
          setTimeout(() => {
            this.dialog = false
          }, 3000)
        }
      }
    },
    Nocotiza: function (val) {
      if (val) {
        this.sinCotizacion();
      }
    }
  },
  components: {
    CotizacionIndividual,
    CotizacionIndividualAxa
  },
  methods: {
    async downloadPdf() {
      if (this.ready) {
        await downloadCotizacion(
          this.cotizaciones,
          this.usuarioJson.name,
          this.usuarioJson.lastname
        );
      }
    },
    async enviarCotizacion() {
      if (this.ready) {
        this.loading = true;
        let send = await sendCotizacion(
          this.cotizaciones,
          this.aseguradoraMeta.aseguradora,
          this.usuarioJson.email
        );
        this.loading = false;
        this.dialog = send;
        setTimeout(() => {
          this.dialog = false;
        }, 4000);
      }
    },
    async sinCotizacion() {
      this.ModalNoCotiza = true;
      envioSinCotizar(this.usuarioJson, this.aseguradoraMeta.aseguradora, this.modelo, this.marca, this.submarca, this.vehiculo, this.plan);
      setTimeout(() => {
        this.ModalNoCotiza = false;
      }, 4000);
    }
  },
};
</script>
