export default async function downloadCotizacion(cotizaciones, name, lastname){
    let config = {
        responseType: "blob",
    }
    let {data} = await window.axios.post('/v1/cotizacion/pdf', cotizaciones,config);
    require("downloadjs")(
        data,
        `${name}_${lastname}_CotizacionSegurify.pdf`,
        "application/pdf"
    );
    // download(
    //     data,
    //     "cotizacion.pdf",
    //     "application/pdf"
    // );
}

