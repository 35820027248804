import Vue from 'vue'
import VueRouter from 'vue-router'

import Base from "@/Base.vue";
import Home from '@/views/Home.vue'

import NosotrosView from "@/views/home/static/NosotrosView.vue"
import TerminosView from "@/views/home/static/TerminosView.vue"
import PrivacidadView from "@/views/home/static/PrivacidadView.vue"
import AyudaView from "@/views/home/static/AyudaView.vue"
import SiniestroView from "@/views/home/static/SiniestroView.vue"
import ContactoView from "@/views/home/static/ContactoView.vue"
import PageNotFound from '@/views/components/PageNotFound.vue'
// import { aseguradora, patchs } from "@/plugins/parameters.js";

Vue.use(VueRouter)

const routes = [
  {
    path: '/:aseguradora?',
    component: Base,
    redirect: { name: 'Home' },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: 'acerca-de',
        name: 'About',
        component: NosotrosView
      },
      {
        path: 'aviso',
        name: 'Aviso',
        component: PrivacidadView
      },
      {
        path: 'terminos',
        name: 'Terminos',
        component: TerminosView
      },
      {
        path: 'siniestro',
        name: 'Siniestro',
        component: SiniestroView
      },
      {
        path: 'faq',
        name: 'Ayuda',
        component: AyudaView
      },
      {
        path: 'contacto',
        name: 'Contacto',
        component: ContactoView
      },
      { 
        path: "404",
        name: '404', 
        component: PageNotFound
      }
    ]
  },
  // { 
  //   path: "*",
  //   name: '404', 
  //   component: PageNotFound
  // }
]

const router = new VueRouter({
  
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeResolve((to, from, next)=>{
  // console.log("entre en el to");
  // console.log(to);
  // console.log("entre en el from");
  // console.log(from);

  // if(to.params.aseguradora != undefined){
  //   let split = to.path.split("/");
  //   console.log(split.length);

  //   let nameAseguradora = null;
  //   let pathRoute = null;
  //   if(split.length > 2){
  //     nameAseguradora = split[1];
  //     pathRoute = split[2];
  //   }else{
  //     nameAseguradora = undefined;
  //     pathRoute =  split[1];
  //   }

  //   console.log(nameAseguradora);
  //   console.log(pathRoute);
  //   if(aseguradora.includes(nameAseguradora)){
  //     console.log('entre en aseguradoras');
  //     return next();
  //   }else if( patchs.includes(pathRoute) ){
  //     console.log('entre en path');
  //     return next();
  //   }else{
  //     console.log('no se busco nada');
  //     next({name: '404', replace:true});
  //   }
  // //   let split = to.path.split("/");
  // //   if( patchs.includes(split[2])){
  // //     return next();
  // //   }else{
  // //     return next({name: '404', params:{
  // //       aseguradora: to.params.aseguradora
  // //     }});
  // //   }
  // //  next({name: '404'});
  // }else{
  // //   if( patchs.includes(to.path)){
  // //     return next();
  // //   }else{
  // //     return next({name: '404'});
  // //   }
  //  return next();
  // }

  // next({name: '404'});
  return next();
});

router.beforeEach((to, from, next)=>{
  return next();
});

export default router
