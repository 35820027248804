<template>
  <router-view/>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'App',
    computed: {
        ...mapState(['aseguradoraMeta'])
    },    
    beforeCreate(){
      let host = window.location.host;
      if(host.split(".")[1] != "segurify"){
        let aseguradora = this.$route.params.aseguradora
        if(aseguradora == undefined){ // en caso de que no se envie nada, lo mando con comparador
          aseguradora = 'comparador'
        }
        this.$store.dispatch('getAseguradora', aseguradora);
      }
    },
    mounted(){
      this.$vuetify.theme.themes.light.primary = this.aseguradoraMeta.color;
      // en caso de que la url que se escribio no coincida, mando el error 404
      if(this.aseguradoraMeta.notFound){ 
        this.$router.push({name: '404',  params: {aseguradora: null}})
      }
    },
    metaInfo(){
      return{
        title: `Segurify, Cotiza tu seguro de auto en minutos`,
        meta: [{
          vmid: 'description',
          name: 'description',
          content: this.aseguradoraMeta.meta.descripcion,
        }, {
          vmid: 'keywords',
          name: 'keywords',
          content: "Segurify",
        }]
      }
    },
  }
</script>

<style>

</style>
