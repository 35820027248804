<template>
    <div>
        <v-app-bar app color="primary" height="83" dark elevation="6">
            <div class="d-flex align-center" style="background-color:white;">
                <v-img :src="aseguradoraMeta.img" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="110"
                    height="75" contain></v-img>
            </div>

            <v-spacer></v-spacer>

            <v-app-bar-nav-icon @click="drawer = true" class="mr-3"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer app v-model="drawer" temporary>
            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                    <v-list-item @click="getRoute('Home')">
                        <v-list-item-title>¡Cotiza!</v-list-item-title>
                    </v-list-item>

                    <template v-if="aseguradoraMeta.has_menu">
                        <v-list-group
                            no-action
                            :value="false"
                        >
                            
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>Sobre Nosotros</v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item @click="getRoute('About')">
                                <v-list-item-title>Acerca de Segurify</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="getRoute('Aviso')">
                                <v-list-item-title>Aviso de privacidad</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="getRoute('Terminos')">
                                <v-list-item-title>Términos y condiciones</v-list-item-title>
                            </v-list-item>
                        </v-list-group>

                        <v-list-group
                            no-action
                            :value="false"
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>Ayuda</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            
                            <v-list-item @click="getRoute('Ayuda')">
                                <v-list-item-title>Preguntas Frecuentes</v-list-item-title>
                            </v-list-item>
                        </v-list-group>

                        <v-list-item  @click="getRoute('Siniestro')">
                            <v-list-item-title>Siniestro</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="getRoute('Contacto')">
                            <v-list-item-title>Contacto</v-list-item-title>
                        </v-list-item>
                    </template>

                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Base',
    data: () => ({
        image: null,
        drawer: false,
        group: null,
    }),
    computed: {
        ...mapState(['aseguradoraMeta'])
    },
    beforeMount() { },
    methods: {
        refresh() {
            location.reload();
        },
        getRoute(name = 'Home') {
            if (name == 'Home') {
                if (this.$route.name == 'Home') {
                    location.reload();
                } else {
                    this.ValidRoute(name);
                }
            }
            this.ValidRoute(name);
        },
        ValidRoute(name) {
            if (this.aseguradoraMeta.name != null) {
                this.$router.push({ name: name, params: { aseguradora: this.aseguradoraMeta.aseguradora }, replace: true });
            } else {
                if (this.aseguradoraMeta.notFound) {
                    this.$router.push({ name: '404', params: { aseguradora: null }, replace: true })
                } else {
                    this.$router.push({ name: name, params: { aseguradora: null }, replace: true })
                }
            }
        }
    },
};
</script>


<style>
.v-toolbar__content {
    border-bottom: 8px solid #555559cc !important;
}
</style>